<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">Protocol Spoofing</h2>
      <p class="desc">
        Protocol spoofing plays an important role in data communication and
        improves performance. When a subsisting protocol is not sufficiently
        illustrated by high omission rates and lengthy delays, then we opt for
        the technological factors which confirm to be a productive solution.
      </p>
    </div>
    <div class="info-img col-lg-4 text-center">
      <img
        class="w-100"
        src="../../../../assets/networking/wan/protocol-spoofing.png"
        alt="Wan Accelerator"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "sixth-wan-section",
};
</script>
