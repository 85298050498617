<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-orange h1">What is the purpose of Wan Optimization?</h2>
      <p class="desc">
        Wan Optimization allows you to analyze the performance of a network and
        the application, along with detection of the end-user experience.
      </p>
      <p class="desc">
        IT has the capacity to troubleshoot and deal with issues by combining
        application, network and end-user efficacy for deep visibility. You can
        improve the dexterity of your business by using a complete package of
        network services and business-oriented, purpose-based international
        strategies with regional applicability.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/networking/wan/wan-accelerator.png"
        alt="Wan Accelerator"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "first-wan-section",
};
</script>
