<template>
  <wan-solution />
</template>

<script>
import WanSolution from "../../components/solutions/networking/WanSolution.vue";

export default {
  components: { WanSolution },
  name: "wan-solution-page",
};
</script>

<style></style>
