<template>
  <LeftSection>
    <div class="info-desc col-lg-8">
      <h2 class="title-green h1">Data Caching</h2>
      <p class="desc">
        There is an incorporated intelligence in Caching servers which saves the
        most previously and repeatedly bidden information. This holds importance
        in the deterrence of future requests from crossing a
        networking/wan/Internet link unnecessarily. They also possess a
        timestamp of their recent update. The caching server will cite a locally
        stored sample of the web page if the page timestamp fails to rectify
        when a user visits the page. This will conserve time as the need to load
        the page from across the Internet would be eliminated.
      </p>
    </div>
    <div class="info-img col-lg-4">
      <img
        class="w-100"
        src="../../../../assets/networking/wan/data-storage1.png"
        alt="Wan Accelerator"
      />
    </div>
  </LeftSection>
</template>

<script>
export default {
  name: "seventh-wan-section",
};
</script>
