<template>
  <RightSection>
    <div class="info-desc col-lg-8 pt-3">
      <h2 class="title-yellow h1">
        The usefulness of WAN Optimization Solutions
      </h2>
      <ul>
        <li class="desc">
          <p>
            Rapidly settling the issues with boosted ID visibility into
            application, network and end-user application
          </p>
        </li>
        <li class="desc">
          <p>
            The perfect way for the end-user experience across the Hybrid WAN
            with the boosted operation of all applications
          </p>
        </li>
        <li class="desc">
          <p>
            Having a supervision with a concentrated and conscious approach that
            clarifies business objective into application-centric service
            agreements and itself originates the possible network path chances
            based on accessibility and availability
          </p>
        </li>
      </ul>
    </div>
    <div class="info-img col-lg-4 text-center">
      <img
        class="w-75"
        src="../../../../assets/networking/wan/banner.png"
        alt="Wan Accelerator"
      />
    </div>
  </RightSection>
</template>

<script>
export default {
  name: "eightth-wan-section",
};
</script>
